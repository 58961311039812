<section class="count-box section">
    <div class="overlay"></div>
    <div class="row col-md-12 col-sm-12">
        <div class="col-md-4 mb-4">
            <div class="count">25+</div>
            <div class="count-item">Training Sessions</div>
        </div>

        <div class="col-md-4 mb-4">
            <div class="count">100+</div>
            <div class="count-item">Trained Hours</div>
        </div>
        <div class="col-md-4 mb-4">
            <div class="count">1000+</div>
            <div class="count-item">Satisfied Learners</div>
        </div>
    </div>
</section>