<section id="testimonial-nav" class="section">
    <div class="container">
        <div class="section-header">
            <p class="btn btn-subtitle wow fadeInDown" data-wow-delay="0.2s">Reviews</p>
            <h2 class="section-title">Clients Who Loved Us</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">

                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="testimonial-item">
                                <div class="author">
                                    <div class="img-thumb"><img src="assets/testimonial/testimonial_1.png" alt=""></div>
                                </div>
                                <div class="content-inner">
                                    <p class="description">&quot;We are pleased with the quality of education, and services that you provided. We sincerely appreciate your responsiveness and the way you conducted the faculty development training. We appreciate your support
                                        towards the upbringing of beginners in the development area.&quot;
                                    </p>
                                    <div class="author-info">
                                        <h2><a>Dr. Reema Mathew A</a></h2>
                                        <span>Vimal Jyothi Engineering College, Kannur</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="testimonial-item">
                                <div class="author">
                                    <div class="img-thumb"><img src="assets/testimonial/testimonial_2.png" alt=""></div>
                                </div>
                                <div class="content-inner">
                                    <p class="description">&quot;The workshop was insightful. Hands on experience was good for the students to learn.&quot;</p>
                                    <div class="author-info">
                                        <h2><a>Pramod Mathew Jacob</a></h2>
                                        <span>Providence College of Engineering, Chengannur</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="testimonial-item">
                                <div class="author">
                                    <div class="img-thumb"><img src="assets/testimonial/testimonial_3.png" alt=""></div>
                                </div>
                                <div class="content-inner">
                                    <p class="description">&quot;Your presentation on Machine Learning with Python along with Convolutional Neural Networks was well received by our members. The information in your speech and the additional material you provided were both fascinating
                                        and timely&quot;</p>
                                    <div class="author-info">
                                        <h2><a>Dr. S. K. B. Sangeetha</a></h2>
                                        <span>SRM Institute of Science & Technology, Vadapalani, Chennai</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="testimonial-item">
                                <div class="author">
                                    <div class="img-thumb"><img src="assets/testimonial/testimonial_4.png" alt=""></div>
                                </div>
                                <div class="content-inner">
                                    <p class="description">&quot;It is one of the best learning experiences I had. I am satisfied with the training and the resource person. The courses are well designed with practical orientation.&quot;</p>
                                    <div class="author-info">
                                        <h2><a>Vinitha A V</a></h2>
                                        <span>Thejus Engineering College, Vellarakkad, Thrissur</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="testimonial-item">
                                <div class="author">
                                    <div class="img-thumb"><img src="assets/testimonial/testimonial_5.png" alt=""></div>
                                </div>
                                <div class="content-inner">
                                    <p class="description">&quot;The session was interactive and the teaching skill of the resource person is excellent.&quot;</p>
                                    <div class="author-info">
                                        <h2><a>Mr. Renjith P K</a></h2>
                                        <span>Toc H Institute of Science and Technology, Ernakulam</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <i class="bi bi-arrow-left" aria-hidden="true"></i>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <i class="bi bi-arrow-right" aria-hidden="true"></i>
                        <span class="sr-only">Next</span>
                    </a>
                </div>

                <!-- <div id="testimonials" class="owl-theme owl-carousel">
                    <div class="item">
                        <div class="testimonial-item">
                            <div class="author">
                                <div class="img-thumb"><img src="img/testimonial/img1.png" alt=""></div>
                            </div>
                            <div class="content-inner">
                                <p class="description">Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthusiastically architect adaptive. Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts
                                    for change. Enthu.</p>
                                <div class="author-info">
                                    <h2><a href="#">Mark Parker</a></h2>
                                    <span>UIdeck</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimonial-item">
                            <div class="author">
                                <div class="img-thumb"><img src="img/testimonial/img1.png" alt=""></div>
                            </div>
                            <div class="content-inner">
                                <p class="description">Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthusiastically architect adaptive. Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts
                                    for change. Enthu.</p>
                                <div class="author-info">
                                    <h2><a href="#">Mark Parker</a></h2>
                                    <span>UIdeck</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimonial-item">
                            <div class="author">
                                <div class="img-thumb"><img src="img/testimonial/img1.png" alt=""></div>
                            </div>
                            <div class="content-inner">
                                <p class="description">Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthusiastically architect adaptive. Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts
                                    for change. Enthu.</p>
                                <div class="author-info">
                                    <h2><a href="#">Mark Parker</a></h2>
                                    <span>UIdeck</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>