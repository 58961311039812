<header *ngIf="course" id="home" class="hero-area-2">

    <div class="overlay"></div>
    <div class="container">
        <div class="row space-100">
            <div class="col-lg-7 col-md-12 col-xs-12">
                <div class="contents">
                    <h2 class="head-title">{{course.name}}</h2>
                    <h5>Duration : {{course.duration}}</h5>
                    <p>{{course.description}}</p>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 col-xs-12">
                <div class="intro-img">
                    <img src="{{course.imageLink}}" alt="">
                </div>
            </div>
        </div>
    </div>
</header>

<div *ngIf="course" class="topic">
    <div class="section-header">
        <p class="btn btn-subtitle wow fadeInDown" data-wow-delay="0.2s">Topics</p>
    </div>
    <ul>
        <li *ngFor="let topic of course.topics">{{topic}}</li>
    </ul>
</div>

<div *ngIf="showLoader" id="preloader">
    <div><img src="assets/AUXILEO_LABS_logo.png"></div>
    <div class="loader" id="loader-1"></div>
</div>