<section class="footer-Content">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-mb-12">
                <img style="height: 100px;" src="assets/images/logo_3.png" alt="">
                <div class="textwidget">
                    <p>Auxileo labs is an education technology company that empowers learners by providing quality training on recent technologies.</p>
                </div>
                <div class="social">
                    <a target="_blank" href="https://www.linkedin.com/in/auxileo-labs-30245320a/"><i class="bi bi-linkedin"></i></a>
                    <a target="_blank" href="https://twitter.com/auxileolabs"><i class="bi bi-twitter"></i></a>
                    <a target="_blank" href="https://www.instagram.com/auxileolabs/"><i class="bi bi-instagram"></i></a>
                    <a target="_blank" href="https://www.youtube.com/channel/UCrUGo6i4HHlsJg31x38rVmA"><i class="bi bi-youtube"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-mb-12">
                <div class="widget">
                    <h3 class="block-title">Resource</h3>
                    <ul class="menu">
                        <li><a [routerLink]="['']">Home</a></li>
                        <li><a [routerLink]="['all-courses']">Courses</a></li>
                        <li><a [routerLink]="['all-video']">Videos</a></li>
                        <li><a [routerLink]="['all-blogs']">Blogs</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-mb-12">
                <div class="widget">
                    <h3 class="block-title">Contact</h3>
                    <ul class="menu">
                        <li>info@auxileolabs.com</li>
                        <li>+91-7306090948</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="site-info float-left">
                        <p>Copyright &copy; 2021, MB Designs. All Rights R eserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>