<div id="app-features" class="section">
    <div class="container">
        <div class="section-header">
            <p class="btn btn-subtitle wow fadeInDown" data-wow-delay="0.2s">Features</p>
            <h2 class="section-title wow fadeIn" data-wow-delay="0.2s">Amazing Features</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12 col-xs-12">
                <div class="content-left text-right">
                    <div class="box-item left">
                        <span class="icon"><i class="bi bi-people"></i></span>
                        <div class="text">
                            <h4>Expert Instructions</h4>
                            <p>Learn from our experts and get real-time guidance.</p>
                        </div>
                    </div>
                    <div class="box-item left">
                        <span class="icon"><i class="bi bi-calendar3"></i></span>
                        <div class="text">
                            <h4>Flexible Schedule</h4>
                            <p>Reschedule your batch/class at your convenience.</p>
                        </div>
                    </div>
                    <div class="box-item left">
                        <span class="icon"><i class="bi bi-braces"></i></span>
                        <div class="text">
                            <h4>Hands on training</h4>
                            <p>Learn new knowledge by doing experiments.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-xs-12">
                <div class="show-box"><img src="assets/images/topic.png" alt=""></div>
            </div>
            <div class="col-lg-4 col-md-12 col-xs-12">
                <div class="content-right text-left">
                    <div class="box-item right">
                        <span class="icon"><i class="bi bi-camera-reels"></i></span>
                        <div class="text">
                            <h4>Live classes</h4>
                            <p>Live online and interactive classes by instructors.</p>
                        </div>
                    </div>
                    <div class="box-item right">
                        <span class="icon"><i class="bi bi-clock"></i></span>
                        <div class="text">
                            <h4>24X7 support</h4>
                            <p>Personalized guidance from our 24X7 support team.</p>
                        </div>
                    </div>
                    <div class="box-item right">
                        <span class="icon"><i class="bi bi-app"></i></span>
                        <div class="text">
                            <h4>Real-time Applications</h4>
                            <p>Learn through real-time applications.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>