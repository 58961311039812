<section *ngIf="videos.length>0" id="videos-nav" class="screens-shot section">
    <div class="container">
        <button type="submit" *ngIf="showMore" class="btn btn-common btn-search" [routerLink]="['all-video']">more <i class="bi bi-arrow-right"></i></button>
        <div class="section-header">
            <p class="btn btn-subtitle wow fadeInDown" data-wow-delay="0.2s">Videos</p>
            <h2 class="section-title">YouTube Videos</h2>
        </div>

        <div class="row">
            <div>
                <div class="row col-md-12">
                    <div *ngFor="let v of videos" class="col-lg-3 col-md-3 col-xs-12 col-12 item shadow m-2" (click)=open(content,v)>
                        <!--Repeate-->
                        <div class="screenshot-thumb"><img class="img-fluid" src="{{v.thumbnail}}" alt=""></div>
                        <div class="blog-item-text">
                            <h3><a>{{v.heading}}</a></h3>
                            <div class="author">
                                <span class="name"><a href="#">{{v.topic}}</a></span>
                                <span class="date float-right">{{v.author}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<ng-template #content let-modal>
    <div class="youtube">
        <youtube-player [videoId]="id" (ready)="savePlayer($event)" (change)="onStateChange($event)"></youtube-player>
    </div>
</ng-template>