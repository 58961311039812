<div id="mission" class="section">
    <div class="header">
        <app-nav-other [showOnlyHeader]="true"></app-nav-other>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="img-thumb wow fadeInLeft" data-wow-delay="0.3s">
                    <img class="img-fluid" src="assets/images/teachers.png" alt="">
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="text-wrapper wow fadeInRight" data-wow-delay="0.6s">
                    <div>
                        <p class="btn btn-subtitle">About Us</p>
                        <p>Auxileo labs is an education technology company that empowers learners by providing quality training on advanced technologies. We promote applied learning and redefines the learning process in a research-oriented manner.</p>
                        <p>Auxileo Labs is dedicated to providing outstanding faculty development training. Across the globe, we provide intelligent, updated instructional classes to educational institutes and the corporate sector. Our team of industry and
                            academic experts facilitates rigorous technology courses to ensure that everybody has the best possible learning experience.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-wrapper wow fadeInRight">

            <p>In Auxileo Labs, the learner explores the content and directly apply new knowledge. We provide training for the learner to apply the learned content through critical thinking. </p>
        </div>
        <!--<div class="row mt">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="text-wrapper wow fadeInRight" data-wow-delay="0.9s">
                    <div>
                        <p class="btn btn-subtitle">Mission & Vission</p>
                        <h3>Free Updates!</h3>
                        <p>Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthusiastically architect adaptive e-tailers after sustainable total linkage.</p>
                        <a class="btn btn-rm" href="#">Read More <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="img-thumb wow fadeInLeft" data-wow-delay="1.2s">
                    <img class="img-fluid" src="assets/images/mission.png" alt="">
                </div>
            </div>
        </div>-->
    </div>
</div>