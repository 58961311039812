<div>
    <router-outlet></router-outlet>

    <footer>
        <app-footer></app-footer>
    </footer>
</div>



<a *ngIf="showGoUp" (click)="scrollTop()" class="back-to-top">
    <i class="bi bi-arrow-up-short"></i>
</a>