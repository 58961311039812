<section id="courses" class="section">
    <div class="container">
        <button *ngIf="showMore" type="submit" class="btn btn-common btn-search" [routerLink]="['all-courses']">more <i class="bi bi-arrow-right"></i></button>
        <div class="section-header">
            <p class="btn btn-subtitle wow fadeInDown" data-wow-delay="0.2s">Courses</p>
            <h2 class="section-title">Our Top Courses</h2>
        </div>
        <div class="row">
            <div *ngFor="let c of courses" class="col-lg-3 col-md-6 col-xs-12">
                <div class="single-team wow fadeInUp" data-wow-delay="0.2s" [routerLink]="['all-courses/course-details']" [queryParams]="{courseId : c.courseId}">
                    <div class="team-thumb">
                        <img src="{{c.imageLink}}" alt="">
                    </div>
                    <div class="team-details">
                        <div class="team-inner">
                            <h4 class="team-title">{{c.name}}</h4>
                        </div>
                        <div class="row col-md-12">
                            <p class="duration col-md-10">{{c.duration}}</p>
                            <div class="more col-md-2"><i class="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>