<header id="home" class="hero-area-2">
    <app-nav></app-nav>
    <div class="overlay"></div>
    <div class="container">
        <div class="row space-100">
            <div class="col-lg-7 col-md-12 col-xs-12">
                <div class="contents">
                    <div class="heading">
                        <h4>WELCOME TO</h4>
                        <h2>AUXILEO LABS</h2>
                    </div>
                    <p>Auxileo labs is an education technology company that empowers learners by providing quality training on advanced technologies. We promote applied learning and redefines the learning process in a research-oriented manner.</p>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 col-xs-12">
                <div class="intro-img">
                    <img src="assets/images/students_2.png" alt="">
                </div>
            </div>
        </div>
    </div>
</header>

<app-team></app-team>

<app-mission-vission></app-mission-vission>

<app-counts></app-counts>

<app-topics></app-topics>

<app-videos></app-videos>

<app-blogs></app-blogs>

<app-testimonial></app-testimonial>