<header id="home" class="hero-area-2">

    <div class="overlay"></div>
    <app-nav-other [showOnlyHeader]="false"></app-nav-other>
    <div class="container">
        <div class="row space-100">
            <div class="col-lg-7 col-md-12 col-xs-12">
                <div class="contents">
                    <h2 class="head-title">VIDEOS</h2>
                    <p>Auxileo labs is an education technology company that empowers learners by providing quality training on recent technologies. We promote applied learning and redefines the learning process in a research-oriented manner. </p>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 col-xs-12">
                <!--<div class="intro-img">
                    <img src="assets/images/students.png" alt="">
                </div>-->
            </div>
        </div>
    </div>
</header>

<section *ngIf="videos.length>0" id="videos" class="screens-shot section">
    <div class="container">
        <div class="row">
            <div class="touch-slider">
                <div class="row col-md-12">
                    <div *ngFor="let v of videos" class="col-lg-3 col-md-3 col-xs-12 col-12 item shadow m-2" (click)=open(content,v)>
                        <!--Repeate-->
                        <div class="screenshot-thumb"><img class="img-fluid" src="{{v.thumbnail}}" alt=""></div>
                        <div class="blog-item-text">
                            <h3><a>{{v.heading}}</a></h3>
                            <div class="author">
                                <span class="name"><a href="#">{{v.topic}}</a></span>
                                <span class="date float-right">{{v.author}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<ng-template #content let-modal>
    <div class="youtube">
        <youtube-player [videoId]="id" (ready)="savePlayer($event)" (change)="onStateChange($event)"></youtube-player>
    </div>
</ng-template>

<div *ngIf="showLoader" id="preloader">
    <div><img src="assets/AUXILEO_LABS_logo.png"></div>
    <div class="loader" id="loader-1"></div>
</div>