<header id="home" class="hero-area-2">

    <div class="overlay"></div>
    <app-nav-other [showOnlyHeader]="false"></app-nav-other>
    <div class="container">
        <div class="row space-100">
            <div class="col-lg-7 col-md-12 col-xs-12">
                <div class="contents">
                    <h2 class="head-title">COURSES</h2>
                    <p>The courses at Auxileo Labs are designed in a student centric manner. Our course structure gives utmost importance for applied learning. Apart from gaining a theoretical knowledge about the subject, the learners will get an opportunity
                        to test their subject knowledge on real- time applications. </p>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 col-xs-12">
                <!--<div class="intro-img">
                    <img src="assets/images/students.png" alt="">
                </div>-->
            </div>
        </div>
    </div>
</header>

<section id="courses" class="section">
    <div class="container">
        <div class="row">
            <div *ngFor="let c of courses" class="col-lg-3 col-md-6 col-xs-12">
                <div class="single-team wow fadeInUp" data-wow-delay="0.2s" [routerLink]="['course-details']" [queryParams]="{courseId : c.courseId}">
                    <div class="team-thumb">
                        <img src="{{c.imageLink}}" alt="">
                    </div>
                    <div class="team-details">
                        <div class="team-inner">
                            <h4 class="team-title">{{c.name}}</h4>
                        </div>
                        <div class="row col-md-12">
                            <p class="duration col-md-10">{{c.duration}}</p>
                            <div class="more col-md-2"><i class="bi bi-arrow-right"></i></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div *ngIf="showLoader" id="preloader">
    <div><img src="assets/AUXILEO_LABS_logo.png"></div>
    <div class="loader" id="loader-1"></div>
</div>