import { Component, OnInit, HostListener, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-nav-other',
  templateUrl: './nav-other.component.html',
  styleUrls: ['./nav-other.component.scss']
})
export class NavOtherComponent implements OnInit {

  @Input() showOnlyHeader : boolean;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    if(this.showOnlyHeader){
      document.getElementById('nav').classList.add('menu-bg');
    }
  }

  @HostListener('window:scroll', [])  
  onWindowScroll() {
    if(!this.showOnlyHeader){
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById('nav').classList.add('menu-bg');
      }else{
        document.getElementById('nav').classList.remove('menu-bg');
      }
    }
  }

}
